import React from "react";
import "./Lsm2023.css";
import NavbarComponent from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

const Lsm2023 = () => {
  const [activeTab, setActiveTab] = React.useState("overview");

  const navigate = useNavigate();
  const navigateTo = (route: string) => {
    navigate(route);
  };
  return (
    <div className="EventInfo pt-5">
      <NavbarComponent />
      <div className="event-info-content-wrapper text-center px-3">
        <div className="lsm2025-wrapper">
          <div onClick={() => navigateTo("/lsm2025")} className="lsm2025">
            Go to LSM 2025
          </div>
        </div>
        <div className="row col-lg-12 col-12 m-auto">
          {/* Overview Section Start */}
          <div
            className={
              activeTab == "overview" ? "row col-lg-12 px-0 mx-auto" : "d-none"
            }
          >
            <div className="event-logo-wrapper mx-0 mt-3">
              <img
                className="event-logo mx-auto my-0"
                src={require("../../assets/Images/lsm_2023_logo.png")}
              />
              <p className="my-1 mx-auto sub-text">2023</p>
            </div>
            <div className="row col-lg-12 mx-auto my-4">
              <div className="col-lg-4 col-12">
                <div className="features-card my-2 px-2">250+ voices</div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="features-card my-2 px-2">
                  Professional Orchestra
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="features-card my-2 px-2">
                  Vocal Workshops and more
                </div>
              </div>
            </div>
            <div text-center>
              <div className="lsm2025-wrapper-mobile">
                <div onClick={() => navigateTo("/lsm2025")} className="lsm2025">
                  Go to LSM 2025
                </div>
              </div>
              <h4 className=" my-0 mt-4">
                JANUARY 27th (FRI) & 28th (SAT), 2023 | Coimbatore, Tamil Nadu,
                India
              </h4>
            </div>

            <div className="row m-auto col-lg-12 col-12 testimonial-item d-block my-2">
              <div className="avatar-wrapper col-lg-12 col-12"></div>
              <div className="col-lg-12 col-12 testimonial-content">
                <h5 className="testimonial-title text-center mt-3">
                  LSM 2023 Report
                </h5>
                <p className="testimonial-desc mt-4">
                  Let’s Sing Messiah 2023 - the 2-day national choral convention
                  drew more than 220 choral enthusiasts from across India on Jan
                  27 th and 28 th 2023. Over the two days, registered
                  participants gathered in for a workshop and rehearsal sessions
                  where singers had the unique experience of learning and
                  interacting with fellow musicians. Participants got to bond
                  over games following a special catered gala dinner. Concert
                  morning was dedicated to sound checks and a combined rehearsal
                  culminating to the final performance that took place at
                  Hindustan College of Arts And Science. The concert featured
                  conductors Augustine Paul and Faith Ragland who led a 35-
                  piece orchestra, guest soloists and the 220-voice chorus to a
                  spectacular presentation of Handel’s Messiah. Both the choir
                  and the orchestra moved with remarkable agility that evening.
                  The Chief guest for the event, an acclaimed pianist, Mr Dhruva
                  Seshadri, engaged the audience playing Chopin’s Scherzo adding
                  a rich hue to the program.
                </p>
                <p className="testimonial-desc">
                  Conceptualised and hosted by Coimbatore Chamber Chorale, LSM
                  23 in all its entirety, created the avenue for amateur singers
                  and musicians to come together, build camaraderie and tell the
                  epic story of Messiah in a professional setting.
                </p>
              </div>
            </div>

            <div className="col-lg-12 col-12 mx-auto my-3 mt-4">
              <h4 className="recap-title">Day 1 Recap</h4>
              <iframe
                width="100%"
                height="480"
                src={`https://www.youtube.com/embed/9wQMyxApCRw?si=S3dfrZjrwsqZGpiL`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>

            <div className="col-lg-12 col-12 mx-auto my-3 mt-4">
              <h4 className="recap-title">Day 2 Recap</h4>
              <iframe
                width="100%"
                height="480"
                src={`https://www.youtube.com/embed/GKW6ubImx_s?si=h0ivZWJNlCG31iPi`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div>

            <hr className="event-hr col-lg-9 col-11 my-4" />

            {/* Testimonials start */}

            <h4>Testimonials</h4>

            <div className="col-lg-4 col-12 my-2 px-2">
              <div className="testimonial-item">
                <div className="col-lg-12 col-12 testimonial-content">
                  <h5 className="testimonial-title">Riddhiman Dutta</h5>
                  <h5 className="testimonial-designation">
                    Kolkata, West Bengal
                  </h5>
                  <p className="testimonial-desc mt-2">
                    The whole experience was beautiful. I loved interacting with
                    different people from choirs around the country. This was
                    also the first time I sang The Messiah, that too with such
                    an amazing choir and beautiful orchestra.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 my-2 px-2">
              <div className="testimonial-item">
                <div className="col-lg-12 col-12 testimonial-content">
                  <h5 className="testimonial-title">Antony Lyngdoh</h5>
                  <h5 className="testimonial-designation">
                    Shillong, Megalaya
                  </h5>
                  <p className="testimonial-desc mt-2">
                    I really appreciate the initiative that you have taken. It's
                    really awesome and wonderful as a choir members from
                    Shillong that we are able to take part in such a beautiful
                    events. I also would like to thank you all for your support,
                    helping, take care of us.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 my-2 px-2">
              <div className="testimonial-item">
                <div className="col-lg-12 col-12 testimonial-content">
                  <h5 className="testimonial-title">David Regenold Joshua</h5>
                  <h5 className="testimonial-designation">
                    Secunderabad, Telangana
                  </h5>
                  <p className="testimonial-desc mt-2">
                    {" "}
                    We were able to winess very good soloists and great singers
                    from both within and out side of India. Specially both
                    Master conductors giving the tips and hints on singing which
                    is highly appreciated. Well organised event one of its kind.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 my-2 px-2">
              <div className="testimonial-item">
                <div className="col-lg-12 col-12 testimonial-content">
                  <h5 className="testimonial-title">Kurian George</h5>
                  <h5 className="testimonial-designation">Kottayam, Kerala</h5>
                  <p className="testimonial-desc mt-2">
                    Really enjoyed singing with some amazing musicians. Great
                    co-ordination from everyone and I personally felt like my
                    music sense improved, I was refreshed at the end of it.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 my-2 px-2">
              <div className="testimonial-item">
                <div className="col-lg-12 col-12 testimonial-content">
                  <h5 className="testimonial-title">Samuel David</h5>
                  <h5 className="testimonial-designation">
                    Bengaluru, Karnataka
                  </h5>
                  <p className="testimonial-desc mt-2">
                    Everything was well planned and organized. Right from the
                    registration, the food, breakout sessions, everything was
                    perfect. Enjoyed the rehearsal sessions and the concert.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 my-2 px-2">
              <div className="testimonial-item">
                <div className="col-lg-12 col-12 testimonial-content">
                  <h5 className="testimonial-title">Simon Peter</h5>
                  <h5 className="testimonial-designation">
                    Vallioor, Tamil Nadu
                  </h5>
                  <p className="testimonial-desc mt-2">
                    The best singing experience I have ever had. The resonance
                    of the orchestra and the choir is still ringing in my heart
                    after more than 2 weeks.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 my-2 px-2">
              <div className="testimonial-item">
                <div className="col-lg-12 col-12 testimonial-content">
                  <h5 className="testimonial-title">B. Benshia Manolin</h5>
                  <h5 className="testimonial-designation">
                    Chennai, Tamil Nadu
                  </h5>
                  <p className="testimonial-desc mt-2">
                    The whole initiative with all its efforts was such an
                    impressive feat. The events that preceded the Gala dinner
                    was one of the major highlights.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 my-2 px-2">
              <div className="testimonial-item">
                <div className="col-lg-12 col-12 testimonial-content">
                  <h5 className="testimonial-title">Melinda Susan Thomas</h5>
                  <h5 className="testimonial-designation">
                    Thoothukudi, Tamil Nadu
                  </h5>
                  <p className="testimonial-desc mt-2">
                    It was a delight to be a part of the LSM, a lot of learning
                    with respect to diction, style, the conductors perspective
                    on how a particular piece is to be sung. Enjoyed the
                    conference.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12 my-2 px-2">
              <div className="testimonial-item">
                <div className="col-lg-12 col-12 testimonial-content">
                  <h5 className="testimonial-title">Susan K John</h5>
                  <h5 className="testimonial-designation">
                    Coonoor, Tamil Nadu
                  </h5>
                  <p className="testimonial-desc mt-2">
                    A bold initiative by the Coimbatore Chamber Chorale, which
                    was carried out meticulously. The portal and interface were
                    state of the art.
                  </p>
                </div>
              </div>
            </div>

            {/* Testimonials end */}

            <h4 className="my-2">Gallery</h4>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/1.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/2.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/3.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/4.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/5.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/6.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/7.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/8.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/9.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/10.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/11.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/12.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/13.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/14.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/15.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/16.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/17.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/18.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/19.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/20.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/21.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/22.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/23.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/24.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/25.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/26.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/27.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/28.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/29.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/30.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/31.JPG")} />
            </div>

            <div className="col-lg-6 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/32.JPG")} />
            </div>

            <div className="col-lg-12 col-12 my-2 px-2 images">
              <img src={require("../../assets/Images/lsm2023/33.JPG")} />
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Lsm2023;
