import React, { useEffect } from 'react';
import './EventsExtended.css';
import Navbar from '../Navbar/Navbar'
import ScrollToTop from '../services/scrollToTop';
import apiInstance from '../services/httpService';
import Footer from '../Footer/Footer';

const EventsExtended = () => {
  const monthMapObj = { "01": "JAN", "02": "FEB", "03": "MAR", "04": "APR", "05": "MAY", "06": "JUN", "07": "JUL", "08": "AUG", "09": "SEP", "10": "OCT", "11": "NOV", "12": "DEC" }
  const [pastEvents, setPastEvents] = React.useState([])
  const [upcomingEvents, setUpcomingEvents] = React.useState([])
  useEffect(() => {
    apiInstance.axiosCall('/api/ccc/events/public', null, 'GET').then((response) => {
      if (response && response.data.concerts) {
        setPastEvents(response.data.concerts.past)
        setUpcomingEvents(response.data.concerts.upcoming)
      }
      // setIsLoaded(true)
    })
  }, []);
  return (
    <div className="EventsExtended">
      <ScrollToTop />
      <Navbar />
      <div className='row col-lg-12 mx-0'>
        <div className='events-banner col-lg-12'>
          <h1 className='events-banner-title mx-auto'>Events</h1>
        </div>
      </div>

      <div className='col-lg-10 m-auto px-2 events-wrapper'>
        <div className='mt-4 d-flex'>
          <h4>Upcoming Events</h4>
        </div>

        <div className='row event-item-row'>

          {/* Start Rendering the upcoming events recieved via props */}
          {
            upcomingEvents?.map((event) =>
              <div className='col-lg-4 my-3 event-item-wrapper'>
                <div className='event-item'>
                  <img className='event-img' src={event.event_thumbnail ? event.event_thumbnail : require("../../assets/Images/Ladies.jpg")} />
                  <div className='row event-info p-3'>
                    <div className='col-lg-3 col-3 text-center mb-3'>
                      <h3 className='m-0 mt-1 event-month'>{monthMapObj[event.date_details.date.split('/')[1]]}</h3>
                      {
                        event.date_details.dummy_date == false ?
                          <h3 className='m-0 event-date'>{event.date_details.date.split('/')[0]}</h3>
                          :
                          ''
                      }
                      <h3 className='m-0 mt-1 event-year'>{event.date_details.date.split('/')[2]}</h3>
                    </div>
                    <div className='col-lg-9 col-9'>
                      <h5 className='event-title' title={event.display_name}>{event.display_name}</h5>
                      <p className='m-0 event-desc' title={event.description}>{event.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {/* End Rendering the upcoming events recieved via props */}

          {
            upcomingEvents.length == 0 ? <div className='col-lg-12 my-3'><div className='no-events-found col-lg-12'>No Events Found.</div></div> : ''
          }

        </div>

      </div>


      <div className='divider row m-0 my-5'>
        <img src={require("../../assets/Images/divider.png")} />
      </div>


      <div className='col-lg-10 m-auto px-2 events-wrapper'>
        <div className='mt-4 d-flex'>
          <h4>Past Events</h4>
        </div>

        <div className='row event-item-row mb-4'>

          {/* Start Rendering the upcoming events recieved via props */}
          {
            pastEvents?.map((event) =>
              <div className='col-lg-4 my-3 event-item-wrapper'>
                <div className='event-item'>
                  <img className='event-img' src={event.event_thumbnail ? event.event_thumbnail : require("../../assets/Images/Ladies.jpg")} />
                  <div className='row event-info p-3'>
                    <div className='col-lg-3 col-3 text-center mb-3'>
                      <h3 className='m-0 mt-1 event-month'>{monthMapObj[event.date_details.date.split('/')[1]]}</h3>
                      {
                        event.date_details.dummy_date == false ?
                          <h3 className='m-0 event-date'>{event.date_details.date.split('/')[0]}</h3>
                          :
                          ''
                      }
                      <h3 className='m-0 mt-1 event-year'>{event.date_details.date.split('/')[2]}</h3>
                    </div>
                    <div className='col-lg-9 col-9'>
                      <h5 className='event-title' title={event.display_name}>{event.display_name}</h5>
                      <p className='m-0 event-desc' title={event.description}>{event.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {/* End Rendering the upcoming events recieved via props */}

          {
            pastEvents.length == 0 ? <div className='col-lg-12 my-3'><div className='no-events-found col-lg-12'>No Events Found.</div></div> : ''
          }

        </div>

      </div>

      <Footer />
    </div>
  )
};

export default EventsExtended;
