import React, { useEffect } from 'react';
import './SetPassword.css';
import TextField from '@mui/material/TextField';
import apiInstance from '../../services/httpService';
import { useSearchParams } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import sha256 from 'crypto-js/sha256';

const SetPassword = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [passwordData, setPasswordData] = React.useState({
    password: '',
    confirm_password: ''
  })
  const [formData, setFormData] = React.useState()
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success')
  const [message, setMessage] = React.useState('')
  const [searchParams, setSearchParams] = useSearchParams();

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    if (formData) {
      setPassword()
    }
  }, [formData])

  const handleChange = e => {
    const { name, value } = e.target;
    setPasswordData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const submitForm = e => {
    var passwordExpression = /^(?=.*[0-9])[a-zA-Z0-9_!@#$%^&*]{6,16}$/;
    // const history = useHistory()
    if (passwordData.password == passwordData.confirm_password) {
      if (!passwordExpression.test(passwordData.password)) {
        setSeverity('error')
        setMessage("Password must be atleast 6 Characters, with a number.")
        setOpen(true)
        return false;
      }
      if (passwordData.password)
        setFormData({
          password: sha256(passwordData.password).toString(),
          mail_token: searchParams.get("token")
        });
    } else {
      setSeverity('error')
      setMessage("Passwords doesn't match.")
      setOpen(true)
      return false;
    }
  };

  const setPassword = () => {
    apiInstance.axiosCall('api/ccc/user/pwd_reset', formData, 'POST').then((response) => {
      if (response.data.status == 'success') {
        localStorage.setItem('token', response.data.token)
        setSeverity('success')
        setMessage(response.data.message)
        setOpen(true)
        setTimeout(navigateTo('/memberArea'), 3000);
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
        return false;
      }
    })
  }

  const navigate = useNavigate();
  const navigateTo = (route) => {
    navigate(route);
  }


  return (
    <div className="SetPassword px-4">
      <div className='setPassword-wrapper row col-lg-6'>
        <div className='p-0'><img src={require("../../../assets/Images/Ladies.jpg")} /></div>

        <div className='form-section row py-3 m-auto'>
          <h3 className='set-up-label'>Setup your Password</h3>
          <div className='col-lg-12 my-2 form-item'>
            <TextField className='col-lg-12' autoComplete='off' id="outlined-basic" label="Password" variant="outlined" type="password" name='password' onKeyUp={handleChange} />
          </div>
          <div className='col-lg-12 my-2 form-item'>
            <TextField className='col-lg-12' autoComplete='off' id="outlined-basic" label="Confirm Password" type="password" variant="outlined" name='confirm_password' onKeyUp={handleChange} />
          </div>
          <div className='col-lg-12 my-2 form-item'>
            <div className='know-more-btn mt-2 m-auto col-lg-3 justify-content-center btn-effects' onClick={submitForm}>Submit</div>
          </div>
          <div className='col-lg-12 my-2'>
            <label className='password-criterion-label'>Password Rules:</label>
            <p className='password-criterion-label my-0'>- Password must contain atleast one number.</p>
            <p className='password-criterion-label my-0'>- Password must have a minimum of 6 characters.</p>
          </div>
        </div>
      </div>
      {/* Toast */}
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SetPassword;
