import React, { FC } from 'react';
import './Quote.css';

interface QuoteProps { }

const Quote: FC<QuoteProps> = () => (
  <div className="Quotes">
    <img className='quotes-icon' src={require("../../assets/Images/quotes-icon.png")} />
    <p>I should be sorry if I only entertained them. I wish to make them better.</p>
    <hr className='mt-5'></hr>
    <p className='author-label my-2'>George Frideric Handel</p>
  </div>
);

export default Quote;
