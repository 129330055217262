import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.scss";
import Home from "./components/Home/Home";
import HomeContent from "./assets/content-authoring/home.json";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import apiInstance from "./components/services/httpService";
import MediaExtended from "./components/MediaExtended/MediaExtended";
import EventsExtended from "./components/EventsExtended/EventsExtended";
import Contact from "./components/Contact/Contact";
import AboutExtended from "./components/AboutExtended/AboutExtended";
import Museed from "./components/Museed/Museed";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import CancellationRefundPolicy from "./components/CancellationRefundPolicy/CancellationRefundPolicy";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Lsm2025 from "./components/Lsm2025/Lsm2025";
import Lsm2023 from "./components/Lsm2023/Lsm2023";
import Register from "./components/old/Register/Register";
import Login from "./components/old/Login/Login";
import SetPassword from "./components/old/SetPassword/SetPassword";
import MemberArea from "./components/old/MemberArea/MemberArea";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import C5 from "./components/c5/c5";

function App() {
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [eventsData, setEventsData] = React.useState([]);

  const theme = createTheme({
    palette: {
      primary: {
        light: "#fff",
        main: "#3a8bac",
        dark: "#000",
      },
      secondary: {
        main: "#f44336",
      },
    },
  });

  useEffect(() => {
    apiInstance
      .axiosCall("/api/ccc/events/public", null, "GET")
      .then((response: any) => {
        setEventsData(response.data.concerts.upcoming);
        setIsLoaded(true);
      })
      .catch((error: any) => {
        setIsLoaded(true);
        setEventsData([]);
        console.log("::Please check internet connection::");
      });
  }, []);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<Home content={HomeContent} events={eventsData} />}
            />
            <Route path="/media" element={<MediaExtended />} />
            <Route path="/events" element={<EventsExtended />} />
            <Route path="/about" element={<AboutExtended />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/museed" element={<Museed />} />
            <Route path="/c5" element={<C5 />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route
              path="/cancellation-refund-policy"
              element={<CancellationRefundPolicy />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/lsm2025" element={<Lsm2025 />} />
            <Route path="/lsm2023" element={<Lsm2023 />} />

            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/setPassword" element={<SetPassword />} />

            <Route path="/memberArea/*" element={<MemberArea />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
