import React, { FC } from 'react';
import styles from './Faq.module.scss';

interface FaqProps {
  content: any;
}

const Faq: FC<FaqProps> = ({ content }) => (
  <div className={styles.Faq} data-testid="Faq">
    {
      content.map((item: any, index: number) => (
        <div>
          <p className={styles.Faq__question}>{index + 1}. {item.question}?</p>
          <p className={styles.Faq__answer}>{item.answer}</p>
        </div>
      ))
    }
  </div>
);

export default Faq;
