import React from 'react';
import './PrivacyPolicy.css';
import Navbar from '../Navbar/Navbar'
import ScrollToTop from '../services/scrollToTop';
import Footer from '../Footer/Footer';

const PrivacyPolicy = () => (
  <div className="PrivacyPolicy">
    <ScrollToTop />
    <Navbar />
    <div className='row col-lg-12 mx-0'>
      <div className='privacy-banner col-lg-12'>
        <h1 className='privacy-banner-title mx-auto'>Privacy Policy</h1>
      </div>
    </div>
    <div className='row col-lg-12 mx-0 p-3'>
      <div className='policy-desc col-lg-12'>We value your trust when you register to our website. We are committed to safeguarding your privacy and your information will not be divulged to any irrelevant third party. Your contact information are only shared with our admin team for communication with you. They have no permission to use the information for any other purposes. We do not ask for your credit card number as payments are made by you to our account directly. Your information such as your name, address, emails and phone numbers are collected when you register to ship practice materials and to provide additioanal details about the event, mail you on any updates of the events. This enables us to keep you upto date with our plans, and to improve our engagement with you.</div>
      <h4 className='my-3'>Policy changes & revisions</h4>
      <div className='policy-desc col-lg-12'>
        Our society regulations changes from time to time and we may make amendments or changes to our policies and this notice. We may email reminders of changes in our notices and policies, unless we have received instructions not to. You are advised to visit our website frequently to stay updated.

        We will never materially change our policies to cause loss of protection of your information. If there are any questions regarding this privacy policy you may contact us using the information below:
      </div>
      <div className='mt-3'>Society Name : Coimbatore Chamber Chorale</div>
      <div>Address : 102, C1, 8th Street, Sriram Nagar, Podanur, Coimbatore, Tamilnadu, India - 641 023</div>
      <div>Telephone No: +91 86810 70777</div>
      <div>E-Mail ID : info@coimbatorechamberchorale.com</div>
    </div>
    <Footer />
  </div>
);

export default PrivacyPolicy;
