import React, { FC } from 'react';
import styles from './Museed.module.scss';
import ScrollToTop from '../services/scrollToTop';
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer';

interface MuseedProps { }

const Museed: FC<MuseedProps> = () => (
  <div className={styles.Museed}>
    <ScrollToTop />
    <Navbar />
    <div className='row col-lg-12 mx-0'>
      <div className={styles.Museed__banner}>
        <h1 className={styles.Museed__banner_title}>Museed</h1>
      </div>
    </div>
    <div className={styles.Museed__content}>
      <div className={styles.Museed__content_pics}>
        <img src={require("../../assets/Images/museed_1.jpeg")} />
        <img src={require("../../assets/Images/museed_2.jpeg")} />
        <img src={require("../../assets/Images/museed_3.jpeg")} />
      </div>
      <p>Having progressed over the years in performing various genres of music across various stages,
        members of CCC had a unifying urge to share the joy of art music with everyone in the community.
        To take quality music education to the fringes of our community and to make art music accessible to
        all, CCC initiated MuSeed in the year 2021.</p>
      <p>Marginalised communities are in various pockets of our city and choir members located one such
        group in the Kakkan Nagar surrounding. Children there are of all age groups. They are just as
        dynamic and vibrant as any child and the one thing that separates them from the rest of their peer is
        the lack of opportunity. To begin with, members of CCC visited the community and began teaching
        the children some basics through singing and a little music theory. Very quickly the children were
        introduced to the fundamentals of playing the violin. But why the violin? Playing a stringed
        instrument is a skill that isn't easily acquired. It builds confidence and when played in a group as in
        an orchestra, it imparts a positive team spirit.</p>
      <p>MuSeed is largely volunteer driven. A team of proactive youngsters, many of whom are violinists
        themselves have come forward to teach. MuSeed began with around 30 children, out of which, 10
        have shown good progress. What stands out among these children is the musical aptitude that
        remained latent within them. It had to be tapped. Word spread across ardent music lovers who have
        contributed violins and other music accessories for the cause.</p>
      <p>Futuristic goals include taking these children to advanced levels of violin training and to provide
        learning opportunities that are designed to nurture these children on their unique musical
        experiences. The journey ahead may not be an easy one but who knows, there could be a virtuoso
        lurking among them and MuSeed could be a gateway to a career in music or an opportunity for a
        deserving child to reach his or her full potential.</p>
    </div>
    <Footer></Footer>
  </div>
);

export default Museed;
