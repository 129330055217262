import React from 'react';
import './AboutExtended.css';
import Navbar from '../Navbar/Navbar'
import ScrollToTop from '../services/scrollToTop';
import Footer from '../Footer/Footer';

const AboutExtended = () => (
  <div className="AboutExtended">
    <ScrollToTop />
    <Navbar />
    <div className='row col-lg-12 mx-0'>
      <div className='about-banner col-lg-12'>
        {/* <h1 className='brand-name m-auto my-2'>CCC</h1> */}
        <h1 className='about-banner-title mx-auto'>About</h1>
      </div>
    </div>

    <div className='row align-items-center m-0 about-choir'>
      <h1 className='section-heading col-lg-12 mt-4 text-center'>The Choir</h1>
      <div className='col-lg-6 choir-image text-center'>
        <img src={require("../../assets/Images/choir1.png")} />
      </div>
      <div className='choir-desc col-lg-6 d-flex align-items-center'>Coimbatore Chamber Chorale, a 50 member choral ensemble came into being in 2011 under the baton of its founder and artistic director Faith Ragland with a goal to bring western classical music to a wider audience. Over the last decade, the choir has been exploring a wide range of repertoire drawn from the baroque and classical era. From a capella polyphony to evening vespers, sacred music has also been embraced by the ensemble in their regular church singing. Through their exemplary performances, the group has developed noteworthy reputation for musical excellence. Discipline and commitment are the core values of the choir and these values have been adhered to through the years. Conductor Faith has consistently worked with the choristers to achieve unified vowel sounds, refined diction and a pure balanced choral tone. With a blend of young competent musicians and experienced singers, the group remains fresh and vibrant. CCC is the first choir from Coimbatore to have cleared the silver choral assessment by Trinity college, London. (TCL)</div>
    </div>

    <div className='choir-desc col-lg-12'>
      <h4>Collaborations</h4>
      The choir has garnered the unique experience of collaborating with other choirs, guest conductors and musicians. The key to the group’s success has been their ability to interact and evolve. One of the first collaborations of CCC was with Vienna university choir presenting Mozart’s Missa Brevis in 2013, conducted by Maestro Vijay Upadhyaya. CCC shares a long-standing association with Madras Musical Association (MMA) and conductor Augustine Paul. Together the choirs presented Handel’s famed three act oratorio Judas Maccabaeus in 2015 and Israel in Egypt HWV54 in 2022. Other collaborative works include, Mozart’s Coronation Mass with Handel Manuel Chorus and Felix Mendelssohn’s Elijah with the Trichy concert choir in 2017. To exhibit versatility in music genres, CCC performed a Rock and Pop concert to a packed audience. The choir was accompanied by the popular band Blue Note in 2016.
    </div>

    <div className='choir-desc col-lg-12'>
      <h4>Workshops and Masterclasses</h4>
      In addition to the regular series of concerts, CCC has offered performance workshops for professional and amateur singers. These workshops and masterclasses have encouraged young musicians to view music from different perspectives – theological, historical and literary. Through these sessions, experienced conductors interact with the participants enhancing their skills and knowledge. The COVID pandemic in 2020 had CCC venturing into digital projects. The virtual CCC presented Felix Mendelsohn’s ‘As the hart pants’, Alice Hawthorne’s ‘Whispering Hope’ and ‘Christmas blessings’ through the 2020 lockdown. CCC remains dedicated to performance of high standards. With innovative ideas and consistent practices, director Faith Ragland led his choir to advanced heights of musicianship. The group seeks to inspire the young generation and foster a high level of artistry in our community.
    </div>



    <div className='divider row m-0 my-5'>
      <img src={require("../../assets/Images/divider.png")} />
    </div>

    <div className='row m-0 align-items-center about-conductor text-center'>
      <h1 className='section-heading col-lg-12 mt-4 text-center'>The Conductor</h1>
      <div className='conductor-content row m-0 col-lg-12'>
        <div className='conductor-desc col-lg-6 col-12'>
          <p>Faith Ragland is a violinist, cellist and music educator with a rich experience in choral conducting.</p>
          <p>At 10, Faith began to take formal violin lessons and had won several awards which includes Late Dr. P. Subbaroyan’s memorial prize and M. Kalyan’s award for his outstanding performances in grade exams conducted by TCL. Under the tutelage of Michael Glenn Mott, Faith played for The Tirunelveli Chamber Orchestra founded by Cleophas Anthony. Over the years, Faith performed with two of India’s finest orchestras - The Delhi Symphony Orchestra and Indian National Youth Orchestra. He plays cello and first violin in Coimbatore Strings Ensemble. Their wide range of repertoire encompasses Mozart’s Symphony in G major, Vivaldi’s Sinfonia in C major and Bach’s Brandenburg’s concerto to name a few.</p>
          <p>Faith worked as music director of Dr. SMCSI Medical College Choir, Karakonam for 6 years. This 60-voice institutional choir performed at various cultural events and used to present 3 concerts every year.</p>
        </div>
        <div className='col-lg-6 col-12 conductor-image'>
          <img src={require("../../assets/Images/conductor.png")} />
        </div>
      </div>
      <div className='conductor-desc row m-0 pt-0 col-lg-12 col-12'>
        <p>In 2011, Faith Ragland founded Coimbatore Chamber Chorale with a group of 35 dedicated singers and a vision to spread the awareness of western classical music among the younger generation. The works in the ensemble’s repertoire extend from early baroque to modern contemporary. Coimbatore Chamber Chorale has appeared at many prestigious venues including Tata Theatre Mumbai, Dubai Opera and Wiener Konzerthaus Vienna.  Among the choir’s benchmark interpretations are Handel’s Messiah, Mendelssohn’s Elijah, Mozart’s Coronation Mass and very recently Handel’s Israel in Egypt.</p>
        <p>Guest conducting engagements has added an extra dimension to Faith’s work. He has been guest conductor for Kuwait Chamber Chorale since its inception.  He is the head of the music department in Stanes school, Coimbatore and conducts the students-faculty choir there. Through his educational efforts, Faith continues to identify and influence the younger generation to extend their musical horizons.</p>
      </div>
    </div>

    <Footer />
  </div>
);

export default AboutExtended;
