import React, { FC } from "react";
import styles from "./c5.module.scss";
import Lottie from "react-lottie-player";
import comingSoonLottie from "../../assets/lotties/comingSoon.json";
import ScrollToTop from "../services/scrollToTop";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

interface c5Props {}

const C5: FC<c5Props> = () => (
  <div className={styles.c5} data-testid="c5">
    <ScrollToTop />
    <Navbar />
    <div className="row mx-0">
      <div className="events-banner col-lg-12 text-center">
        <h1 className="events-banner-title mx-auto">
          CCC Children's Choir (C5)
        </h1>
      </div>
    </div>
    <div className={styles.c5__content}>
      <img src={require("../../assets/Images/c5_1.jpg")} />
      <div className={styles.c5__content_text}>
        <p>
          Recognizing the burgeoning enthusiasm and remarkable aptitude
          displayed by young singers during Coimbatore Chamber Chorale (CCC)
          rehearsals and concerts, we identified a unique opportunity.
          Witnessing their ability to master complex musical pieces, we were
          inspired to cultivate a dedicated space for age-appropriate, engaging,
          and enriching musical exploration.
        </p>
        <p>
          Thus, the Coimbatore Chamber Chorale Children's Choir (C5) was
          established, providing a nurturing environment where young voices
          could delve into the captivating world of choral music. Our mission is
          to foster a lifelong love for singing, creating a space where children
          eagerly gather each week to express themselves through melody.
        </p>
        <h4 className="my-3">Highlights</h4>
        <ul>
          <li>
            <strong>Target Age Group:</strong> Children aged 7 to 12.
          </li>
          <li>
            <strong>Curriculum:</strong> Focus on fundamental musical concepts,
            including pitch, rhythm, and harmony, designed to enhance musical
            proficiency and build vocal confidence.
          </li>
          <li>
            <strong>Dynamic Sessions:</strong> Energetic rehearsals featuring
            interactive warm-up exercises, vocal preparation, and exploration of
            diverse musical themes.
          </li>
          <li>
            <strong>Repertoire:</strong> Performance of esteemed compositions
            such as John Rutter's "For the Beauty of the Earth," David
            Archuleta's "Glorious," the Zambian folk song "Bonse Aba," Shawna
            Edwards' "Risen," and a selection of cherished hymns.
          </li>
          <li>
            <strong>Summer Workshops:</strong> Intensive workshops dedicated to
            comprehensive musical education, encompassing vocal techniques and
            music theory.
          </li>
        </ul>
        <h4 className="my-3">Impact</h4>
        <p>
          In just one year, C5 has successfully nurtured young talents, guiding
          them to deliver polished performances of challenging and diverse
          repertoire. Our commitment extends beyond performance, aiming to
          provide a holistic musical education that empowers children to explore
          and appreciate the art of choral singing.
        </p>
        <p>
          We invite young singers to join the C5 community and embark on a
          rewarding musical journey. Discover the joy of choral music and
          develop your musical potential with us.
        </p>
      </div>
    </div>
    <Footer />
  </div>
);

export default C5;
