import React, { FC } from 'react';
import './Events.css';

interface EventsProps {
  content: any;
  events: any[];
}

const Events: FC<EventsProps> = (props) => {
  const [showMore, setShowMore] = React.useState(false)
  const monthMapObj: any = { "01": "JAN", "02": "FEB", "03": "MAR", "04": "APR", "05": "MAY", "06": "JUN", "07": "JUL", "08": "AUG", "09": "SEP", "10": "OCT", "11": "NOV", "12": "DEC" }

  return (
    <div className="Events">

      <div className='col-lg-10 m-auto events-wrapper'>
        <div className='my-1 d-flex'>
          <h4>Upcoming Events</h4>
        </div>

        <div className='row event-item-row'>

          {/* Start Rendering the upcoming events recieved via props */}
          {
            props.events?.map((event) =>
              <div className='col-lg-4 my-3 event-item-wrapper'>
                <div className='event-item'>
                  <img className='event-img' src={event.event_thumbnail ? event.event_thumbnail : require("../../assets/Images/Ladies.jpg")} />
                  <div className='row event-info p-3'>
                    <div className='col-lg-3 col-3 text-center mb-3'>
                      <h3 className='m-0 mt-1 event-month'>{monthMapObj[event.date_details.date.split('/')[1]]}</h3>
                      {
                        event.date_details.dummy_date == false ?
                          <h3 className='m-0 event-date'>{event.date_details.date.split('/')[0]}</h3>
                          :
                          ''
                      }
                      <h3 className='m-0 mt-1 event-year'>{event.date_details.date.split('/')[2]}</h3>
                    </div>
                    <div className='col-lg-9 col-9'>
                      <h5 className='event-title' title={event.display_name}>{event.display_name}</h5>
                      <p className='m-0 event-desc' title={event.description}>{event.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          {/* End Rendering the upcoming events recieved via props */}

          {
            props.events.length == 0 ? <div className='col-lg-12 my-4'><div className='no-events-found'>No Events Found.</div></div> : ''
          }

        </div>

        <div className={showMore == true ? 'row event-item-row' : 'd-none'}>
        </div>

        <div>
          {/* Load More Button */}
          {
            props.events.length > 3 ?
              <div className={showMore == false && props.events.length != 0 ? 'load-more my-2 mx-auto' : 'd-none'} onClick={() => setShowMore(true)}>Show More</div>
              :
              ''
          }

          {/* Load More Button */}
          <div className={showMore == true ? 'load-more my-2 mx-auto' : 'd-none'} onClick={() => setShowMore(false)}>Show Less</div>
        </div>

      </div>
    </div>
  )
}

export default Events;
