import React, { FC } from "react";
import styles from "./Lsm2025.module.scss";
import NavbarComponent from "../Navbar/Navbar";
import { useCallback } from "react";
import Particles from "react-tsparticles";
import type { Container, Engine } from "tsparticles-engine";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import { ParticleConfig } from "./particle.config";
import Overview from "./Overview//Overview";
import Faq from "../Faq/Faq";
import Registration from "../Registration/Registration";
import lsmContent from "../../assets/content-authoring/lsm.json";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

interface Lsm2025Props {}

const Lsm2025: FC<Lsm2025Props> = () => {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(
    async (container: Container | undefined) => {
      await console.log(container);
    },
    []
  );

  const [activeTab, setActiveTab] = React.useState("overview");

  const navigate = useNavigate();
  const navigateTo: any = (route: any) => {
    navigate(route);
  };

  return (
    <>
      <NavbarComponent />
      <div className={styles.Lsm2025} data-testid="Lsm2025">
        <div className={styles.Lsm2025__head}>
          <Particles
            id="tsparticles"
            className={styles.Lsm2025__head_particles}
            init={particlesInit}
            loaded={particlesLoaded}
            options={ParticleConfig as any}
          />
          <img
            className={styles.Lsm2025__logo}
            src={require("../../assets/Images/lsm_logo.png")}
          />
        </div>

        {/* Highlighted Registration Link*/}
        <div
          onClick={() => navigateTo("/register")}
          className={styles.Lsm2025__registerBtn + " btn-effects"}
        >
          Click to Register
          <img
            className="register-icon ml-2"
            src={require("../../assets/Images/register-icon.png")}
          />
        </div>

        {/* Sub Navbar */}
        <div
          className={
            styles.Lsm2025__tabs + " mx-auto my-4 row col-lg-10 col-12"
          }
        >
          <div
            className={
              activeTab == "overview"
                ? styles.Lsm2025__tabs_active + " col-lg-2 col-6"
                : styles.Lsm2025__tabs_item + " col-lg-2 col-6"
            }
            onClick={() => setActiveTab("overview")}
          >
            Overview
          </div>
          <div
            className={
              activeTab == "registration"
                ? styles.Lsm2025__tabs_active + " col-lg-2 col-6"
                : styles.Lsm2025__tabs_item + " col-lg-2 col-6"
            }
            onClick={() => setActiveTab("registration")}
          >
            Registration
          </div>
          <div
            className={
              activeTab == "faq"
                ? styles.Lsm2025__tabs_active + " active col-lg-2 col-12"
                : styles.Lsm2025__tabs_item + " col-lg-2 col-12"
            }
            onClick={() => setActiveTab("faq")}
          >
            FAQs
          </div>
        </div>

        {/* Sub sections corresponding to the selected tab item */}
        {
          {
            overview: <Overview />,
            faq: <Faq content={lsmContent.faq} />,
            registration: <Registration />,
          }[activeTab]
        }
        <Footer isLsmPage={true}></Footer>
      </div>
    </>
  );
};

export default Lsm2025;
