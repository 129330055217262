import React, { FC } from 'react';
import styles from './Home.module.scss';
import Logo from '../../assets/Images/logo.png'
import PlayIcon from '../../assets/Images/play-icon.png'
import Events from '../Events/Events';
import About from '../About/About';
import Quote from '../Quote/Quote';
import NavbarComponent from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Media from '../Media/Media';
import MuseedHome from '../MuseedHome/Museed';
import { useNavigate } from "react-router-dom";

interface HomeProps {
  content: any;
  events: any[];
}



const Home: FC<HomeProps> = (props) => {
  const navigate = useNavigate();
  const navigateTo: any = (route: any) => {
    navigate(route);
  }
  return (
    <>
      <NavbarComponent></NavbarComponent>
      <div className={styles.Home}>
        <div className={styles.Home__banner}>
          <div className={styles.Home__banner_lsm}>
            <img src={require('../../assets/Images/lsm_banner.png')} onClick={() => navigateTo('/lsm2025')} />
            <button onClick={() => navigateTo('/lsm2025')}>Click here to know more</button>
          </div>
          <h5 className={styles.Home__banner_text}>{props.content.main.titlePrimary}</h5>
          <h5 className={styles.Home__banner_text}>{props.content.main.titleSecondary}</h5>
          <div className={styles.Home__banner_description}>
            <img className={styles.Home__banner_playIcon} src={PlayIcon} />
            <p className={styles.Home__banner_subText} onClick={() => window.open(props.content.main.youtubeLink.url, props.content.main.youtubeLink.targetBehaviour)}>{props.content.main.youtubeLink.text}</p>
          </div>
        </div>
        <img className={styles.Home__banner_logo} src={Logo}></img>
      </div>
      <About></About>
      <Quote></Quote>
      <Events content={props.content.events} events={props.events}></Events>
      <Media></Media>
      <MuseedHome></MuseedHome>
      <Footer></Footer>
    </>
  )
};

export default Home;

