import React, { FC } from "react";
import styles from "./Overview.module.scss";
import ImageSlider from "../../ImageSlider/ImageSlider";
import Lottie from "react-lottie-player";
import connectLottie from "../../../assets/lotties/connect.json";
import handLottie from "../../../assets/lotties/hands.json";
import { useNavigate } from "react-router-dom";

interface OverviewProps {}

const Overview: FC<OverviewProps> = () => {
  const navigate = useNavigate();
  const navigateTo: any = (route: any) => {
    navigate(route);
  };
  return (
    <div className={styles.Overview} data-testid="Overview">
      <div className={styles.Overview__aim + " my-3"}>
        <div className={styles.Overview__aim_statements}>
          <div className={styles.Overview__aim_statements_vision}>
            <h4
              className={
                styles.Overview__aim_statements_vision_title + " mx-auto"
              }
            >
              Our Vision
            </h4>
            <div className={styles.Overview__aim_statements_vision_wrapper}>
              <section>
                <div className={styles.Overview__aim_statements_lottie}>
                  <Lottie loop animationData={connectLottie} play />
                </div>
                <span>
                  Unifying choristers across India and create a platform to
                  perform classical repertoire with a grand choir and a
                  professional orchestra.
                </span>
              </section>
            </div>
          </div>

          <div className={styles.Overview__aim_statements_about}>
            <h4
              className={
                styles.Overview__aim_statements_about_title + " mx-auto"
              }
            >
              Welcome
            </h4>
            <span>
              We're thrilled to invite you to LSM 2025 (Let's Sing Messiah), a
              sprightful gathering where voices unite in shared passion to
              create exquisite and blending harmony. Prepare to be moved by the
              power of collective music-making as we come together to perform
              the timeless masterpiece G F Handel's Messiah. <br />
              <br /> This is not just a concert; it's an opportunity to embark
              on an enriching musical journey, connect with fellow choristers
              from across the country, and craft something truly unforgettable.
            </span>
          </div>
        </div>
      </div>
      <div className={styles.Overview__features}>
        <h4 className={styles.Overview__features_title + " mx-auto"}>
          About LSM 2025
        </h4>
        <div>
          <span className={styles.Overview__features_item}>
            Grand Chorus of 250+ Voices
          </span>
          <span className={styles.Overview__features_item}>
            Professional Orchestra and Soloists
          </span>
          <span className={styles.Overview__features_item}>
            Experienced Conductors
          </span>
          <span className={styles.Overview__features_item}>
            Engaging Rehearsals
          </span>
          <span className={styles.Overview__features_item}>
            Camaraderie and Connection
          </span>
          <span className={styles.Overview__features_item}>
            The Grand Finale Concert
          </span>
        </div>

        <h4 className={styles.Overview__features_title + " mx-auto mt-4"}>
          Dates
        </h4>
        <h4>
          5<sup>th</sup> and 6<sup>th</sup> September 2025
        </h4>
      </div>

      <ImageSlider></ImageSlider>

      <div className={styles.Overview__stepsEligibility}>
        <section>
          <h4 className={styles.Overview__title + " mx-auto"}>
            How does this work?
          </h4>
          <div className={styles.Overview__steps}>
            <ul>
              <li>
                Register for the convention soon after the registrations are
                open.
              </li>
              <li>
                We will share the practice materials including scores and audio
                materials to the registerd participants.
              </li>
              <li>
                Rehearse your section (S|A|T|B) at your own pace, or with your
                registered friends or choir.
              </li>
              <li>
                Join us for the master classes and final rehearsals with full
                orchestra on 5<sup>th</sup>of September, 2025 in Coimbatore, TN.
              </li>
              <li>
                Share the stage with 250 voice choir, grand orchestra and
                soloists for The Grand Finale Concert on 6<sup>th</sup> of
                September 2025.
              </li>
            </ul>
          </div>
        </section>
        <section>
          <h4 className={styles.Overview__title + " mx-auto"}>
            Who can register?
          </h4>
          <div className={styles.Overview__steps}>
            <ul>
              <li>
                Any aspiring chorister who wants to perform classical music with
                a grand orchestra.
              </li>
              <li>
                Any experienced chorister who wishes to make music with like
                minded musicians across the nation.
              </li>
              <li>
                Any passionate singer who desires to experience the grandeur of
                classical music.
              </li>
              <li>
                There is no musical prerequisite to register for this
                convention.
              </li>
            </ul>
          </div>
        </section>
      </div>

      <div className={styles.Overview__lsm2023}>
        <span onClick={() => navigateTo("/lsm2023")}>Visit LSM 2023 Page</span>
      </div>

      <div className={styles.Overview__contact}>
        <div>
          <span className={styles.Overview__contact_item}>
            Phone: +91 86810 70777 | Email: info@coimbatorechamberchorale.com
          </span>
        </div>
      </div>
    </div>
  );
};

export default Overview;
