import React, { useEffect } from 'react';
import './Register.css';
import Navbar from '../../Navbar/Navbar';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Loader from '../../Loader/Loader';
import apiInstance from '../../services/httpService';
import states from '../../../assets/json/states.json';
import countries from '../../../assets/json/countryCodes.json';
import Footer from '../../Footer/Footer';

import sha256 from 'crypto-js/sha256';


// import PartVacancies from '../PartVacancies/PartVacancies'
// import EventInfo from '../EventInfo/EventInfo'

import { useNavigate } from "react-router-dom";
import paymentService from '../../services/paymentService';

const Register = () => {
  const [isRegister, setIsregister] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [emailId, setEmailId] = React.useState('');
  const [value, setValue] = React.useState('');
  const [isEmailVerified, setIsEmailVerified] = React.useState(false);
  const [isOtpFieldEnabled, setIsOtpFieldEnabled] = React.useState(false);
  const [partsStatus, setPartsStatus] = React.useState({})
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState('success')
  const [message, setMessage] = React.useState('')
  const [isUserRegistered, setIsUserRegistered] = React.useState(false)
  const [registeredMessage, setRegisteredMessage] = React.useState('')
  const [otpTimer, setOtpTimer] = React.useState(60)
  const [otpFlag, setOtpFlag] = React.useState(true)
  const [isOTPSent, otpSendStatus] = React.useState(true)
  const [defaultCode, setDefaultCode] = React.useState("+91")
  const [defaultWhatsappCode, setDefaultWhatsappCode] = React.useState("+91")
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = React.useState(true)


  const otpField = React.useRef(null);
  const nameField = React.useRef(null);
  const dobField = React.useRef(null);
  const genderField = React.useRef(null)
  const addressField = React.useRef(null)
  const cityField = React.useRef(null)
  const stateField = React.useRef(null)
  const pincodeField = React.useRef(null)
  const phone_1Field = React.useRef(null)
  const phone_2Field = React.useRef(null)
  const vocal_partField = React.useRef(null)
  const dial_codeField = React.useRef(null)
  const dial_code_2Field = React.useRef(null)
  const workshopField = React.useRef(null)
  const passwordField = React.useRef(null)
  const confirm_passwordField = React.useRef(null)
  const choirField = React.useRef(null)

  const timerInstance = React.useRef();

  const navigate = useNavigate();
  const navigateTo = (route) => {
    navigate(route);
  }

  const label = { inputProps: { 'aria-label': 'discount-checkbox' } };

  const [formData, setFormData] = React.useState({
    name: "",
    dob: "",
    gender: "",
    address_1: "",
    address_2: "",
    address: "",
    state: "",
    country: "India",
    city: "",
    pincode: "",
    phone: "",
    phone_2: "",
    email: "",
    choir: "",
    vocal_part: "",
    workshop: "",
    password: "",
    confirm_password: "",
    dial_code: "+91",
    dial_code_2: "+91",
    discount: ''
  });

  const [emailVerificationData, setEmailVerificationData] = React.useState({
    email: ""
  })

  const [otpVerificationData, setOtpVerificationData] = React.useState({
    email: "",
    otp: null
  })

  const [dateValue, setDateValue] = React.useState(null);
  const [defaultCountry, setDefaultCountry] = React.useState("India");

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const dateChange = (newValue) => {
    var dateString = ("0" + newValue.getDate()).slice(-2) + '/' + ("0" + (newValue.getMonth() + 1)).slice(-2) + '/' + (new Date(newValue).getFullYear())
    setDateValue(newValue);
    setFormData(prevState => ({
      ...prevState,
      ['dob']: dateString
    }));
  };

  const handleEmailDataChange = e => {
    const { name, value } = e.target;
    setEmailVerificationData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const sendOTP = () => {
    if (emailVerificationData.email != '') {
      if (!isValidEmail(emailVerificationData.email)) {
        setSeverity('error')
        setMessage('Please enter a valid email.')
        setOpen(true)
        return false;
      }
      setEmailId(emailVerificationData.email)
      setOtpVerificationData({ email: emailVerificationData.email })
    } else {
      setSeverity('error')
      setMessage('Please enter the email.')
      setOpen(true)
      return false;
    }
    setIsLoading(true)
    apiInstance.axiosCall('api/ccc/user/otp/generate', emailVerificationData, 'POST').then((response) => {
      setIsLoading(false)
      if (response.data.status == 'success') {
        switch (response.data.message) {
          case 'VERIFIED_USER':
            setSeverity('success')
            setMessage('Email Already Verified')
            setOpen(true)
            setFormData(prevState => ({
              ...prevState,
              ['email']: emailVerificationData.email
            }));
            setIsEmailVerified(true)
            break;
          case 'OTP_SENT':
            setSeverity('success')
            setMessage('An OTP is sent to your email.')
            setOpen(true)
            setIsOtpFieldEnabled(true)
            startOtpCountDown()
            break;
          case 'EMAIL_RESTRICTED':
            setSeverity('error')
            setMessage('Sorry, Registrations are over.')
            setOpen(true)
            navigateTo('/registrationsClosed')
            break;
        }
      } else {
        setSeverity('error')
        setMessage(response.data.data.email_status)
        setOpen(true)
      }
    });
  }

  const handleOtpDataChange = e => {
    const { name, value } = e.target;
    setOtpVerificationData(prevState => ({
      ...prevState,
      [name]: parseInt(value)
    }));
  };

  const handleCheckboxChange = e => {
    setFormData(prevState => ({
      ...prevState,
      ['discount']: e.target.checked ? 'yes' : 'no'
    }));
  }

  const verifyOtp = () => {
    if (otpVerificationData.otp == '' || otpVerificationData.otp == null) {
      setSeverity('error')
      setMessage('Please enter the OTP sent to your mail.')
      setOpen(true)
      return false
    }
    setIsLoading(true)
    apiInstance.axiosCall('api/ccc/user/otp/verify', otpVerificationData, 'POST').then((response) => {
      setIsLoading(false)
      if (response.data.status == 'success') {
        setSeverity('success')
        setMessage('Email verified successfully!')
        setOpen(true)
        setFormData(prevState => ({
          ...prevState,
          ['email']: emailId
        }));
        setIsEmailVerified(true)
        endOtpCounter()
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
      }
    });
  }

  const startOtpCountDown = () => {
    setIsRunning(true);
  }

  const [second, setSecond] = React.useState(59);
  const [minute, setMinute] = React.useState(1);
  // Change initial value to `false` if you don't want
  // to have timer running on load
  // Changed `flag` name to more significant name
  const [isRunning, setIsRunning] = React.useState(false);
  // You don't need 2 variable for this
  //const [isClicked, setClicked] = useState(false);

  // Using `useRef` to store a reference to the interval
  const myInterval = React.useRef();

  useEffect(() => {
    // You had this line to start timer on load
    // but you can just set the initial state to `true`
    //setFlag(true);
    // Clear time on component dismount
    return () => clearInterval(myInterval.current);
  }, []);

  // useEffect that start/stop interval on flag change
  useEffect(() => {
    var secCount = 90
    if (isRunning) {
      myInterval.current = setInterval(
        () => {
          if (secCount != 0 && (secCount % 60) == 1) {
            if (minute > 0) {
              setMinute((minute) => minute - 1)
            }
            setSecond((second) => (second - second) + 59)
          } else {
            setSecond((second) => second - 1)
          }
          secCount -= 1
          if (secCount == 0) {
            endOtpCounter()
          }
        }, 1000
      );
    } else {
      clearInterval(myInterval.current);
      myInterval.current = null;
    }
  }, [isRunning]);

  function endOtpCounter() {
    clearInterval(myInterval.current);
    myInterval.current = null;
    setSecond(59);
    setMinute(1);
    setIsRunning(false);
    setIsOtpFieldEnabled(false)
  }

  const resendOtp = () => {
    // to be implemented
  }

  const changeEmailVal = (event) => {
    setEmailId(event.target.value)
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleCodeChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    if (name == 'dial_code') {
      setDefaultCode(e.target.value)
    } else if (name == 'dial_code_2') {
      setDefaultWhatsappCode(e.target.value)
    }
  }

  const handleRadioboxChange = e => {
    setFormData(prevState => ({
      ...prevState,
      ['workshop']: e.target.value == 'yes' ? true : false
    }));
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const displayRazorPay = async (amount, email, phone, eventId, eventName, orderId, transactionId) => {
    const otherReturnData = {
      eventId: eventId,
      transactionId: transactionId,
      amount: amount,
      email: email,
      phone: phone,
      orderId: orderId
    }

    paymentService.createRazorpayInstance(amount, eventName, orderId, `lsm_s_logo.png`, email, phone, confirmPayment, otherReturnData);
    setIsLoading(false)
  }

  const confirmPayment = (response, otherData) => {
    if (response.razorpay_payment_id) {
      // registerUser(response.razorpay_payment_id, otherData.amount, otherData.eventId, otherData.transactionId)
      completePayment(response.razorpay_payment_id, otherData)
    } else {
      setSeverity('error')
      setMessage('Payment Failed. Please check your transaction.')
      setOpen(true)
    }
  }

  const completePayment = (razorpay_payment_id, otherData)=>{
    if(razorpay_payment_id){
      const requestData = {
        payment_amount:otherData.amount,
        transaction_id:otherData.transactionId,
        razorpay_id:razorpay_payment_id,
        order_id: otherData.orderId,
        status:"success",
        event_id:otherData.eventId,
        email_id:otherData.email
    };
      apiInstance.axiosCall('api/ccc/event/payment/guest/complete', {data: requestData}, 'POST').then((response) => {
        if (response.data.status == 'success') {
          setSeverity('success')
          setMessage('Payment done successfully!')
          setOpen(true)
          setRegisteredMessage(response.data.message);
          setIsUserRegistered(true);
          setTimeout(function () {
            navigateTo('/login')
           }, 10000);
        } else {
          setSeverity('error')
          setMessage(response.data.message)
          setOpen(true)
        }
      });
    }
  }

  const initiatePayment = () => {
    if (!formData.name) {
      setSeverity('error')
      setMessage('Name field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      nameField.current.focus();
      return false;
    } else if (!formData.email) {
      
      setSeverity('error')
      setMessage('Email field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      return false;
    } else if (!formData.dob) {
      
      setSeverity('error')
      setMessage('Date Of Birth field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      dobField.current.focus();
      return false;
    } else if (!formData.gender) {
      
      setSeverity('error')
      setMessage('Gender field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true);
      genderField.current.focus();
      return false;
    } else if (!formData.city) {
      
      setSeverity('error')
      setMessage('City field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      cityField.current.focus();
      return false;
    } else if (!formData.state) {
      
      setSeverity('error')
      setMessage('State field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      stateField.current.focus();
      return false;
    } else if (!formData.pincode) {
      
      setSeverity('error')
      setMessage('Pin Code field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      pincodeField.current.focus();
      return false;
    } else if (!formData.phone) {
      
      setSeverity('error')
      setMessage('Phone Number field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      phone_1Field.current.focus();
      return false;
    } else if (!formData.phone_2) {
      
      setSeverity('error')
      setMessage('Whatsapp Number field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      phone_2Field.current.focus();
      return false;
    } else if (!formData.address_1) {
      
      setSeverity('error')
      setMessage('Address field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      addressField.current.focus();
      return false;
    } else if (!isValidEmail(formData.email)) {
      setSeverity('error')
      setMessage('Please enter a valid email.')
      setOpen(true)
      return false;
    } else if (!formData.vocal_part) {
      setSeverity('error')
      setMessage('Vocal Part field is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      vocal_partField.current.focus();
      return false;
    } else if (!formData.dial_code) {
      setSeverity('error')
      setMessage('Dial Code for Phone number is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      dial_codeField.current.focus();
      return false;
    } else if (!formData.dial_code_2) {
      setSeverity('error')
      setMessage('Dial Code for Whatsapp number is Mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      dial_code_2Field.current.focus();
      return false;
    } else if (!formData.password) {
      setSeverity('error')
      setMessage('Password field is mandatory!! Please enter all the mandatory fields.')
      setOpen(true)
      passwordField.current.focus();
      return false;
    } else if (!formData.confirm_password) {
      setSeverity('error')
      setMessage('Please confirm your password by retyping the password!! Please enter all the mandatory fields.')
      setOpen(true)
      confirm_passwordField.current.focus();
      return false;
    } else if (!formData.choir) {
      setSeverity('error')
      setMessage('Please enter your past choir experience and choir. Please enter all the mandatory fields.')
      setOpen(true)
      choirField.current.focus();
      return false;
    }

    var passwordExpression = /^(?=.*[0-9])[a-zA-Z0-9_!@#$%^&*]{6,16}$/;

    if (!passwordExpression.test(formData.password)) {
      setSeverity('error')
      setMessage("Password must be atleast 6 Characters, with a number.")
      setOpen(true)
      return false;
    }

    if (formData.password != formData.confirm_password) {
      setSeverity('error')
      setMessage("Passwords doesn't match.")
      setOpen(true)
      return false;
    }

    if (formData.choir.length > 500) {
      setSeverity('error')
      setMessage("Your choir experience description should not be more than 500 characters.")
      setOpen(true)
      return false;
    }

    var eventId = 1
    var eventDesc = "Registration fee for being a part of the choral group of the event"
    var reqData = {...formData, password: sha256(formData.password).toString()}
    reqData.data = { event_id: 1 }
    apiInstance.axiosCall('api/ccc/user/create', reqData, 'POST').then((response) => {
      if (response.data.status == 'success') {
        if(response.data.data.data.user === 'guest'){
          const transactionDetails = response.data.data.transaction_details;
        displayRazorPay(transactionDetails.pending_amount, transactionDetails.email, transactionDetails.phone, response.data.data.data.event_id, transactionDetails.event_display_name, transactionDetails.order_id, transactionDetails.transaction_id)
        }else{
          setSeverity('success')
          setMessage('Registration done successfully!')
          setOpen(true)
          setRegisteredMessage(response.data.message);
          setIsUserRegistered(true);
          setTimeout(function () {
            navigateTo('/login')
           }, 10000);
        }
      } else {
        setSeverity('error')
        setMessage(response.data.message)
        setOpen(true)
      }
    })
  }

  const registerUser = (razorpayId, amount, eventId, transactionId) => {
    if (!formData.address_1 || !formData.name || !formData.email || !formData.dob || !formData.gender || !formData.city || !formData.state || !formData.phone || !formData.phone_2 || !formData.vocal_part || formData.workshop === '' || !formData.dial_code || !formData.dial_code_2) {
      
      setSeverity('error')
      setMessage('Please enter all the mandatory fields.')
      setOpen(true)
      return false;
    }

    if (!isValidEmail(formData.email)) {
      setSeverity('error')
      setMessage('Please enter a valid email.')
      setOpen(true)
      return false;
    }

    var reqObj = formData
    var paymentData = { email: emailId, payment_amount: amount, transaction_id: transactionId, razorpay_id: razorpayId, event_id: eventId, status: 'success' }
    reqObj.data = paymentData

    setIsLoading(true)
    // apiInstance.axiosCall('api/ccc/user/create', reqObj, 'POST').then((response) => {
    //   if (response.data) {
    //     if (response.data.status == 'success') {
    //       setIsLoading(false)
    //       setRegisteredMessage(response.data.message)
    //       setSeverity('success')
    //       setMessage('User Registration Successful!')
    //       setOpen(true)
    //       setIsUserRegistered(true)
    //       setFormData({
    //         name: "",
    //         dob: "",
    //         gender: "",
    //         address_1: "",
    //         address_2: "",
    //         address: "",
    //         state: "",
    //         country: "India",
    //         city: "",
    //         pincode: "",
    //         phone: "",
    //         phone_2: "",
    //         email: "",
    //         choir: "",
    //         vocal_part: "",
    //         workshop: false
    //       });
    //       setDateValue(null)
    //       setEmailId('')
    //       localStorage.setItem('token', response.data.token)
    //       setTimeout(function () {
    //         navigateTo('/memberArea')
    //       }, 10000);
    //     } else {
    //       var msg = ''
    //       if (response.data.message.length > 1) {
    //         msg = response.data.message.map((item) => msg + item + ' ' + '|<--->|' + ' ')
    //       } else {
    //         msg = response.data.message.map((item) => msg + item)
    //       }
    //       setSeverity('error')
    //       setMessage(msg)
    //       setOpen(true)
    //     }
    //   } else {
    //     setSeverity('error')
    //     setMessage('Something went Wrong!')
    //     setOpen(true)
    //   }
    // })
  };

  function isValidEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  useEffect(() => {
    apiInstance.axiosCall('api/ccc/registration/precondition', null, 'GET').then((response) => {
      setPartsStatus(response.data.data.parts_status)
      setIsLoading(false)
    })
  }, []);

  if (isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <div className="Register">
      <Navbar />
      <div className='row col-lg-12 mx-0'>
        <div className='event-banner col-lg-12'>
          <div className={isRegister == false ? 'register-events-toggle-btn mt-4 m-auto' : 'd-none'} onClick={() => setIsregister(true)}>Register <img className='register-icon ml-2' src={require("../../../assets/Images/register-icon.png")} /></div>
          <div className={isRegister == false ? 'register-warn-label text-center mt-3' : 'd-none'}><label>* Please read the event information before registering.</label></div>
          <div className={isRegister == false ? 'register-warn-label text-center mt-3' : 'd-none'}><label>* If you are a resgistered user, please <a className='login-href' onClick={() => navigateTo('/login')}>Login</a>.</label></div>
          <div className={isRegister == true ? 'register-events-toggle-btn mt-4 m-auto' : 'd-none'} onClick={() => { navigateTo('/lsm2025'); if (isUserRegistered) { setIsEmailVerified(false) } setIsUserRegistered(false); }}>Event Info <img className='register-icon ml-2' src={require("../../../assets/Images/register-icon.png")} /></div>
          <h1 className='event-banner-title mx-auto text-center'>{isRegister == false ? '' : 'Let\'s Sing Messiah'}</h1>
        </div>
      </div>

      {/* If User Not Registered */}
      <div className={isUserRegistered ? 'd-none' : ''}>
        <div className={isRegister == false ? 'desc-section row col-lg-12 m-auto p-0' : 'd-none'}>
          {/* <EventInfo></EventInfo> */}
        </div>
        {/* <div className="Footer">
          <div className={isRegister == false ? 'register-events-toggle-btn mt-4 m-auto' : 'd-none'} onClick={() => setIsregister(true)}>Register <img className='register-icon ml-2' src={require("../../assets/register-icon.png")} /></div>
        </div> */}
        {
          isEmailVerified ?
            <div className='row col-lg-10 m-auto p-0'>
              <div className='d-flex align-items-center justify-content-between py-4'>
                {/* <PartVacancies vacancies={partsStatus} /> */}
              </div>
              <div className='desc-section row col-lg-12 m-auto px-0'>

                <div className='col-lg-6 my-2 form-item'>
                  <TextField className='col-lg-12 mat-text-field' autoComplete='name' id="outlined-basic" label="Full Name *" name='name' variant="outlined" onKeyUp={handleChange} inputProps={{ autoComplete: 'new-password' }} inputRef={nameField} />
                </div>

                <div className='col-lg-6 my-2 form-item'>
                  <TextField className='col-lg-12' autoComplete='emailid' id="outlined-basic" label="Email *" variant="outlined" name='email' disabled={true} value={emailId ? emailId : null} onKeyUp={handleChange} />
                </div>

                {/* Date of Birth */}
                <div className='col-lg-6 my-2 form-item'>
                  <LocalizationProvider className="col-lg-12" dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Date of Birth *"
                      inputFormat="dd/MM/yyyy"
                      value={dateValue ? dateValue : null}
                      name='dob'
                      onChange={dateChange}
                      inputRef={dobField}
                      renderInput={(params) => <TextField className="col-lg-12" {...params} />}
                    />
                  </LocalizationProvider>
                </div>

                {/* Age */}
                <div className='col-lg-6 my-2 form-item'>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Gender *</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Gender *"
                      name='gender'
                      inputRef={genderField}
                      onChange={handleChange}
                    >
                      <MenuItem value={'Male'}>Male</MenuItem>
                      <MenuItem value={'Female'}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div className='col-lg-6 my-2 form-item'>
                  <TextField className='col-lg-12' autoComplete='address1' id="outlined-basic" inputRef={addressField} label="Address Line 1 *" variant="outlined" name='address_1' onKeyUp={handleChange} />
                </div>
                <div className='col-lg-6 my-2 form-item'>
                  <TextField className='col-lg-12' autoComplete='address2' id="outlined-basic" label="Address Line 2" variant="outlined" name='address_2' onKeyUp={handleChange} />
                </div>
                <div className='col-lg-6 my-2 form-item'>
                  <TextField className='col-lg-12' autoComplete='city' id="outlined-basic" label="City *" inputRef={cityField} variant="outlined" onKeyUp={handleChange} name='city' />
                </div>
                <div className='col-lg-6 my-2 form-item'>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">State *</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="city"
                      label="State *"
                      name='state'
                      inputRef={stateField}
                      onChange={handleChange}
                    >
                      {
                        states.states.map((item, i) => <MenuItem value={item}>{item}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className='col-lg-6 my-2 form-item'>
                  <TextField className='col-lg-12' autoComplete='country' id="outlined-basic" label="Country *" variant="outlined" onKeyUp={handleChange} value={defaultCountry} disabled="true" name='country' />
                </div>
                <div className='col-lg-6 my-2 form-item'>
                  <TextField className='col-lg-12' autoComplete='pin' id="outlined-basic" label="Pincode *" inputRef={pincodeField} variant="outlined" type="number" onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
                  }} onKeyUp={handleChange} name='pincode' onWheel={(e) => e.target.blur()} />
                </div>
                <div className='col-lg-2 col-5 my-2 form-item'>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Code *</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="city"
                      inputRef={dial_codeField}
                      value={defaultCode ? defaultCode : null}
                      label="Code *"
                      name="dial_code"
                      onChange={handleCodeChange}
                    >
                      {
                        countries.countries.map((item, i) => <MenuItem value={item.code}>{item.name + ' (' + item.code + ')'}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className='col-lg-4 col-7 my-2 form-item'>
                  <TextField className='col-lg-12' autoComplete='phoneno' inputRef={phone_1Field} id="outlined-basic" label="Phone *" variant="outlined" name='phone' onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                  }} type="number" onKeyUp={handleChange} onWheel={(e) => e.target.blur()} />
                </div>
                <div className='col-lg-2 col-5 my-2 form-item'>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Code *</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="city"
                      inputRef={dial_code_2Field}
                      value={defaultWhatsappCode ? defaultWhatsappCode : null}
                      label="Code *"
                      name='dial_code_2'
                      onChange={handleCodeChange}
                    >
                      {
                        countries.countries.map((item, i) => <MenuItem value={item.code}>{item.name + ' (' + item.code + ')'}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className='col-lg-4 col-7 my-2 form-item'>
                  <TextField className='col-lg-12' inputRef={phone_2Field} autoComplete='altphoneno' id="outlined-basic" label="Whatsapp Number *" variant="outlined" onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                  }} type="number" name='phone_2' onKeyUp={handleChange} onWheel={(e) => e.target.blur()} />
                </div>
                <div className='col-lg-6 my-2 form-item d-flex align-items-center'>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Choir Part *</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      inputRef={vocal_partField}
                      label="Choir Part"
                      name='vocal_part'
                      onChange={handleChange}
                    >
                      <MenuItem value={'Soprano'}>Soprano</MenuItem>
                      <MenuItem value={'Alto'}>Alto</MenuItem>
                      <MenuItem value={'Tenor'}>Tenor</MenuItem>
                      <MenuItem value={'Bass'}>Bass</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className='col-lg-6 my-2 form-item align-items-center d-flex'>
                  {/* <Checkbox name='workshop' onChange={handleCheckboxChange} /><label className='checkbox-label'>Interested in a Pre-Concert Choral Workshop</label> */}
                  {/* <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    inputRef={workshopField}
                    onChange={handleRadioboxChange}
                  >
                    <FormControlLabel value="yes" control={<Radio />} label="Yes, I'm interested in a Pre-Concert Choral Workshop *" />
                    <FormControlLabel value="no" control={<Radio />} label="No, I'm not interested in a Pre-Concert Choral Workshop *" />
                  </RadioGroup> */}
                </div>
                <div className='col-lg-6 my-2 form-item'>
                  <TextField className='col-lg-12' autoComplete='off' inputRef={passwordField} id="outlined-basic" label="Set Your Password *" variant="outlined" type='password' name='password' onKeyUp={handleChange} />
                </div>
                <div className='col-lg-6 my-2 form-item'>
                  <TextField className='col-lg-12' autoComplete='off' inputRef={confirm_passwordField} id="outlined-basic" label="Retype Password *" variant="outlined" type='password' name='confirm_password' onKeyUp={handleChange} />
                </div>
                <div className='col-lg-12 my-2 form-item'>
                  <TextField multiline className='col-lg-12' rows={3} autoComplete='off' inputRef={choirField} id="outlined-basic" label="Present / Past Choir Experience *" variant="outlined" name='choir' onKeyUp={handleChange} />
                </div>
                <div className='col-lg-12 my-2 form-item'>
                  <div className='d-flex discount-wrapper my-3'>
                    <Checkbox className='discountt' name='discount' onChange={handleCheckboxChange} />
                    <div>
                      <label className='checkbox-label'>Avail Student Concession</label>
                      <div><label className='checkbox-label-sub-text'>* To avail this concession, A valid student ID proof and age proof must be sent to the email-id: <b>info@coimbatorechamberchorale.com</b> within 24 hours of registration.</label></div>
                    </div>
                  </div>
                </div>
                <div className='bank-criterion-section py-3'>
                  <p className='my-0'>* UPI available with all banks.</p>
                  <p className='my-0'>* All debit and credit cards accepted.</p>
                  <p className='my-0'>* <b>Internet Banking</b> is temporarily <b>NOT AVAILABLE</b> with the following banks:</p>
                  <div className='my-3'>
                    <div className='row col-lg-12 col-12 align-items-center bank-logo-wrapper'>
                      <div className='col-lg-2 col-6 px-3'><img className='bank-logo' src={require('../../../assets/Images/banks/BOI.png')} /></div>
                      <div className='col-lg-2 col-6 px-3'><img className='bank-logo' src={require('../../../assets/Images/banks/cub.jpg')} /></div>
                      <div className='col-lg-2 col-6 px-3'><img className='bank-logo' src={require('../../../assets/Images/banks/FEDERAL.png')} /></div>
                      <div className='col-lg-2 col-6 px-3'><img className='bank-logo' src={require('../../../assets/Images/banks/hdfc.png')} /></div>
                      <div className='col-lg-2 col-6 px-3'><img className='bank-logo' src={require('../../../assets/Images/banks/sbi.png')} /></div>
                      <div className='col-lg-2 col-6 px-3'><img className='bank-logo' src={require('../../../assets/Images/banks/syndicate.png')} /></div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-12 my-2 form-item'>
                  <div className='pay-register-btn mt-2 m-auto col-lg-6 col-12 justify-content-center' onClick={() => initiatePayment()}>Pay and Register</div>
                </div>
              </div>
            </div>
            :
            <div className={isRegister == true ? 'row col-lg-10 m-auto my-3 px-3' : 'd-none'}>
              <div className='col-lg-6 my-2 mx-auto form-item'>
                {/* <PartVacancies vacancies={partsStatus} /> */}
                <div className='col-lg-12 col-12 d-flex align-items-center justify-content-between'>
                  <h3 className='set-up-label my-2'>Verify your Email</h3>
                  {isOtpFieldEnabled ? <label className='mx-1 timer-label'>OTP exipires in {minute <= 9 ? '0' : ''}{minute}:{second <= 9 ? '0' : ''}{second}</label> : ''}
                </div>
                <TextField className='col-lg-12 my-2' autoComplete='off' InputLabelProps={{ style: { fontSize: 15 } }} inputProps={{ style: { textAlign: 'center', fontSize: 18, fontWeight: 600 } }} id="outlined-basic" label="Email *" variant="outlined" name='email' disabled={isOtpFieldEnabled} value={emailId ? emailId : null} onKeyUp={handleEmailDataChange} onChange={(e) => { changeEmailVal(e) }} />
                {
                  isOtpFieldEnabled ?
                    <TextField className='col-lg-12 my-2' autoComplete='opt' InputLabelProps={{ style: { fontSize: 15 } }} inputProps={{ style: { fontSize: 20, textAlign: 'center', fontWeight: 600 } }} id="outlined-basic" label="Enter OTP from Email*" variant="outlined" name='otp' inputRef={otpField} onKeyUp={handleOtpDataChange} />
                    : ''
                }
                {
                  isOtpFieldEnabled && !isOTPSent ?
                    <div className='col-lg-12 resend-otp-label'><label onClick={() => { otpSendStatus(false) }}>Resend OTP to Email</label></div>
                    : ''
                }
                {
                  isOtpFieldEnabled ?

                    <div className='register-verify-email-btn mt-2 m-auto col-lg-12 col-12 justify-content-center' onClick={() => verifyOtp()}>Verify</div>
                    :
                    <div className='register-verify-email-btn mt-2 m-auto col-lg-12 col-12 justify-content-center btn-effects' onClick={() => sendOTP()}>Send OTP to Email</div>
                }
              </div>
            </div>
        }
      </div>

      {/* If user registered */}
      <div className={isUserRegistered ? '' : 'd-none'}>
        <div className='registered-message-section col-lg-10 col-md-10 col-11 mx-auto my-4'>
          <div className='my-3 mx-auto text-center'><img src={require("../../../assets/Images/tick.png")} /></div>
          <div>{registeredMessage}</div>
        </div>
      </div>

      {/* Success Toast */}
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>

      <Footer />
    </div >
  )
}

export default Register;
