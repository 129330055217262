import React, { FC } from 'react';
import './Museed.css';
import { useNavigate } from "react-router-dom";

interface MuseedHomeProps { }

const MuseedHome: FC<MuseedHomeProps> = () => {
  const navigate = useNavigate();
  const navigateTo = (route: any) => {
    navigate(route);
  }
  return (
    <div className="Museed">
      <div className='row col-lg-10 m-auto align-items-center'>
        <div className='col-lg-5 about-img-wrapper'>
          <img className='about-img' src={require("../../assets/Images/museed_logo.png")} alt="" />
        </div>
        <div className='col-lg-7 pt-4 about-details'>
          <h4 className="about-label">About Mussed</h4>
          <p className='about-desc'>Having progressed over the years in performing various genres of music across various stages, members of CCC had a unifying urge to share the joy of art music with everyone in the community. To take quality music education to the fringes of our community and to make art music accessible to all, CCC initiated MuSeed in the year 2021.</p>
          <div className='know-more-btn mt-4' onClick={() => navigateTo('/museed')}>Know More <img className='arrow-icon ml-2' src={require("../../assets/Images/arrow-icon.png")} /></div>
        </div>
      </div>
    </div>
  )
}

export default MuseedHome;
