import React from 'react';
import './MediaExtended.css';
import Navbar from '../Navbar/Navbar'
import ScrollToTop from '../services/scrollToTop';
import Footer from '../Footer/Footer';

const MediaExtended = () => (
  <div className="MediaExtended">
    <ScrollToTop />
    <Navbar />
    <div className='row col-lg-12 mx-0'>
      <div className='events-banner col-lg-12'>
        <h1 className='events-banner-title mx-auto'>Media</h1>
      </div>
    </div>

    <div className='media-content row col-lg-12 mx-0'>
      <div className='media-item col-lg-6'>
        <img src={require("../../assets/Images/articles/1.jpg")} />
      </div>

      <div className='media-item col-lg-6'>
        <img src={require("../../assets/Images/articles/2.jpg")} />
      </div>

      <div className='media-item col-lg-6'>
        <img src={require("../../assets/Images/articles/3.jpg")} />
      </div>

      <div className='media-item col-lg-6'>
        <img src={require("../../assets/Images/articles/4.jpg")} />
      </div>

      <div className='media-item col-lg-6'>
        <img src={require("../../assets/Images/articles/5.jpg")} />
      </div>

      <div className='media-item col-lg-6'>
        <img src={require("../../assets/Images/articles/6.jpg")} />
      </div>

      <div className='media-item col-lg-6'>
        <img src={require("../../assets/Images/articles/7.jpg")} />
      </div>

      <div className='media-item col-lg-6'>
        <img src={require("../../assets/Images/articles/8.jpg")} />
      </div>
    </div>

    <Footer />
  </div>
);

export default MediaExtended;
