import React, { FC, useEffect, useRef, useState } from 'react';
import './ImageSlider.css';
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface ImageSliderProps { }

const ImageSlider: FC<ImageSliderProps> = () => {
  const sliderRef = useRef(null);
  const scrollAmount = 100; // The amount to scroll when clicking the navigation buttons
  const [images, setImages] = useState([
    {
      id: 1,
      url: require('../../assets/Images/lsm_1.JPG')
    },
    {
      id: 2,
      url: require('../../assets/Images/lsm_2.JPG')
    },
    {
      id: 3,
      url: require('../../assets/Images/lsm_3.JPG')
    },
    {
      id: 4,
      url: require('../../assets/Images/lsm_4.JPG')
    },
    {
      id: 5,
      url: require('../../assets/Images/lsm_5.JPG')
    },
    {
      id: 6,
      url: require('../../assets/Images/lsm_6.JPG')
    },
    {
      id: 7,
      url: require('../../assets/Images/lsm_7.JPG')
    },
    {
      id: 8,
      url: require('../../assets/Images/lsm_8.JPG')
    },
    {
      id: 9,
      url: require('../../assets/Images/lsm_9.JPG')
    },
    {
      id: 10,
      url: require('../../assets/Images/lsm_10.JPG')
    }
  ]);
  return (
    <div className="ImageSlider" data-testid="ImageSlider">
      {/* Left navigation button */}
      <button
        className="nav-btn nav-btn-left"
        onClick={() => {
          let container: any = sliderRef.current;
          container.scrollLeft -= scrollAmount; // Scroll left by the specified amount
        }}
      >
        <ChevronLeftIcon />
      </button>
      {/* Image container */}
      <div className="images-container" ref={sliderRef}>
        {images.map((image: any) => {
          return (
            <img
              className="image"
              alt="sliderImage"
              key={image?.id}
              src={image?.url}
            />
          );
        })}
      </div>
      {/* Right navigation button */}
      <button
        className="nav-btn nav-btn-right"
        onClick={() => {
          const container: any = sliderRef.current;
          container.scrollLeft += scrollAmount; // Scroll right by the specified amount
        }}
      >
        <ChevronRightIcon />
      </button>
    </div>
  )
}

export default ImageSlider;
