import React, { FC } from 'react';
import './About.css';
import { useNavigate } from "react-router-dom";

interface AboutProps { }

const About: FC<AboutProps> = () => {
  const navigate = useNavigate();
  const navigateTo = (route: any) => {
    navigate(route);
  }
  return (
    <div className="About">
      <div className='row col-lg-10 m-auto align-items-center'>
        <div className='col-lg-5 about-img-wrapper'>
          <img className='about-img' src={require("../../assets/Images/creation_2.JPG")} alt="" />
        </div>
        <div className='col-lg-7 pt-4 about-details'>
          <h4 className="about-label">About CCC</h4>
          <p className='about-desc'>The Coimbatore Chamber Chorale is a 50-member registered choral society that was founded by Faith Ragland in the year 2011. The choral ensemble is well-known for its versatility and varied musical expressions. The choir’s repertoire is extensive, encompassing sacred and contemporary themes with western classical music as its core. </p>
          <div className='know-more-btn mt-4 btn-effects' onClick={() => navigateTo('/about')}>Know More <img className='arrow-icon ml-2' src={require("../../assets/Images/arrow-icon.png")} /></div>
        </div>
      </div>
    </div>
  )
}

export default About;
