import React, { FC } from "react";
import styles from "./Registration.module.scss";
import { useNavigate } from "react-router-dom";

interface RegistrationProps {}

const Registration: FC<RegistrationProps> = () => {
  const navigate = useNavigate();
  const navigateTo = (route: string) => {
    navigate(route);
  };
  return (
    <div className={styles.Registration} data-testid="Registration">
      <div className={styles.Registration__advisory}>
        Welcome to LSM 2025 registrations
      </div>
      <div className={styles.Registration__details}>
        <h4 className={styles.Registration__details_title + " mx-auto"}>
          Guidelines for Registration
        </h4>
        <div className={styles.Registration__details_guidelines}>
          <div>
            <h4>If you were already a part of LSM 2023,</h4>
            <ul>
              <li>
                Simply{" "}
                <a
                  href="javascript:void(0)"
                  onClick={() => navigateTo("/login")}
                >
                  login
                </a>{" "}
                using your credentials.
              </li>
              <li>
                In case you forgot your password, use the 'Reset Password'
                option to reset your password.
              </li>
              <li>
                Once you're logged in, you'll find the convention listed in your
                login dashboard as an upcoming event. You can use the 'pay'
                option to make the payment and complete your registration for
                the convention.
              </li>
              <li>
                Once the payment is complete, you will see the convention listed
                under the enrolled events section.
              </li>
            </ul>
          </div>

          <div>
            <h4>If you are a first-time participant in LSM,</h4>
            <ul>
              <li>
                Navigate to the{" "}
                <a
                  href="javascript:void(0)"
                  onClick={() => navigateTo("/register")}
                >
                  Registration page
                </a>{" "}
                or use the 'Click to Register' button above.
              </li>
              <li>Verify your email ID.</li>
              <li>
                Fill in the required personal information and complete the
                payment for the registration fee to gain access to your
                dashboard.
              </li>
              <li>
                Once the registration process is complete,{" "}
                <a
                  href="javascript:void(0)"
                  onClick={() => navigateTo("/login")}
                >
                  login
                </a>{" "}
                to your dashboard and ensure that LSM 2025 appears in the list
                of enrolled events.
              </li>
              <li>
                If you are a group of 10 or more choristers from a choir, you
                can reach out to us at +91 86810 70777 before registration to
                avail choir discount.
              </li>
            </ul>
          </div>
        </div>

        <h4 className={styles.Registration__details_title + " mx-auto"}>
          Registration Fee
        </h4>
        <div className={styles.Registration__details_fee}>
          <div>
            <h4>Individuals</h4>
            <span>
              <h4>
                <b>₹ 2500 /-</b>
              </h4>
            </span>
          </div>
          <div>
            <h4>Students</h4>
            <span>
              <h4>
                <b>₹ 2000 /-</b>
              </h4>
            </span>
          </div>
          <div>
            <h4>Choir (Min. 10 choristers)</h4>
            <span>
              <h4>
                <b>₹ 2000 /-</b>
                {" (per chorister)"}
              </h4>
            </span>
          </div>
        </div>
        <div
          onClick={() => navigateTo("/register")}
          className={styles.Registration__details_btn + " btn-effects"}
        >
          Click to Register
          <img
            className="register-icon ml-2"
            src={require("../../assets/Images/register-icon.png")}
          />
        </div>
        <h4 className={styles.Registration__details_title + " mx-auto"}>
          Need Help?
        </h4>
        <div className={styles.Registration__details_contact}>
          If you encounter any technical difficulties during your registration
          process, please feel free to reach out to us at
          info@coimbatorechamberchorale.com or whatsapp us at +91 86810 70777.
        </div>
      </div>
    </div>
  );
};

export default Registration;
